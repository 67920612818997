<template>
    <div>
      <div id="mapBox"></div>
      <!-- <div id="map"></div> -->
    </div>
</template>
<script>
import { Loader } from "@googlemaps/js-api-loader"; //引入


export default {
  data() {
    return {
      loader2:null
    }
  },
  mounted() {
    this.initMap()
  },
  props:{
    lng:Number,String,
    lat:Number,String,
  },
  created() {
    this.loader2 = new Loader({
      apiKey: "AIzaSyBtVKOqlVKiu6UJah2scDV4Z7Y-epzpbDQ",//之前的key
      version: "weekly",//版本
      libraries: ["places"],
    });
  },
  methods: {
    initMap() {
      this.lng = parseFloat(this.lng)
      this.lat = parseFloat(this.lat)
      // const latLong = new google.maps.LatLng(this.lng ,this.lat);
      let center = {lng: this.lng ,lat:this.lat };//地图中心店
      // let center = {lng:139.75282364128665  ,lat:35.70271647192927 };//地图中心店
      const mapOptions = {
          center: center,
          zoom: 16,
          disableDefaultUI: true,
      };
      this.loader2.load().then((google) => {
          // console.log("?????????????-------------",google);
          const map = new google.maps.Map(
              document.getElementById("mapBox"),
              mapOptions
          );
          google.maps.event.trigger(map, 'resize');
              new google.maps.Marker({
                position: center,
                map,
          });
        }).catch((e) => {
          // do something
        });
    },

    // initMap() {
    //   var url = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBtVKOqlVKiu6UJah2scDV4Z7Y-epzpbDQ&&language=jp&callback=initMap";
    //   var script = document.createElement("script");
    //   script.src = url;
    //   script.setAttribute("async", true);
    //   document.head.appendChild(script);
    //   window.initMap = () => {
    //     var center;
    //     // console.log(this.lat,this.lng)
    //       center = {
    //         lat: this.lat,
    //         lng: this.lng,
    //       };
    //       console.log("window.google.maps---------------s",window.google.maps)
    //       let map = new window.google.maps.Map(document.getElementById("map"), {
    //         center: center || this.center,
    //         zoom: 15,
    //       });
    //   };
    // },
  }
}
</script>
<style scoped="scoped" lang="scss">
  #mapBox,#map{
    widows: 100%;
    height: 300px;
    margin-top: 40px;
  }

  
@media screen and(max-width:768px) {
  #mapBox,#map{
    margin-top: 20px;
  }

}
</style>