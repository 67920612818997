<template>
  <div class="about">
    <banner :picS="bannerS"></banner>
    <div class="profile">
      <titleOne id="about_1" en='enterprise profile' title="会社概要"></titleOne>
      <div v-if="!mobile" class="proFileBox">
        <div v-if="indexData.company_profile">
          <div class="item acea-row row-between"
          v-for="(item, index) in indexData.company_profile" :key="index">
          <img v-if="index % 2 == 0" class="pic" :src="item.img" alt="">
          <div class="txt">
            <p class="en">{{ item.entitle }}</p>
            <h4>{{ item.title }}</h4>
            <div class="cont">
              {{ item.content }}
            </div>
          </div>
          <img v-if="index % 2 > 0" class="pic" :src="item.img" alt="">
        </div>
        </div>
        
      </div>
      <div v-else class="proFileBox">
        <div v-if="indexData.company_profile">
          <div class="item acea-row row-between"
            v-for="(item, index) in indexData.company_profile" :key="index">
            <img class="pic" :src="item.img" alt="">
            <div class="txt">
              <p class="en">{{ item.entitle }}</p>
              <h4>{{ item.title }}</h4>
              <div class="cont">
                {{ item.content }}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- 企业荣誉 -->
    <!-- <div class="honor">
		<titleOne id="about_2" en='Enterprise honor' title="企业荣誉"></titleOne>
		<div class="honorBox">
			<bannerA itemType="honor" jsClass='js_honor' :list="indexData.enterprise_honor" :scale="false" :slides="mobile?2:5" :navigation="false"></bannerA>
		</div>
	</div> -->

    <!-- 团队荣誉 -->
    <div class="team">
      <titleOne id="about_3" en='team introduction' title="会社の風雲児たち"></titleOne>
      <!-- <div class="teamBox" v-if="mobile">
				<bannerA itemType="team" :list="indexData.team_introduction" :scale="false" :slides="1" jsClass='js_team'
					:navigation="true" :mobile="mobile"></bannerA>
			</div>
			<div class="teamBox" v-else>
				<bannerA itemType="team" :list="indexData.team_introduction" jsClass='js_team'></bannerA>
			</div> -->
      <div class="teamBox2" v-for="(item, index) in indexData.team_introduction" :key="index">
        <div class="left">
          <img :src="item.team_img" alt="">
        </div>
        <div class="right">
          <div class="name">{{ item.team_name }}</div>
          <div class="title">{{ item.position_title[0] }}</div>
          <div class="info">{{ item.desc }}</div>
          <!-- <div class="more">クリックしてもっと</div> -->

          <router-link class="more" :to="'/team_detail?id=' + item.id">クリックしてもっと</router-link>
        </div>
      </div>
    </div>

    <!-- <div class="Links" v-if="indexData.team_introduction_zc">
			<div class="widthBox">
				<div class="linksBox">
					<swiperList v-if="mobile" jsClass='js_links' :list="indexData.team_introduction_zc" itemType="links"
						:pagination="false" :oneItem="2" :slides="2" :navigation="false"></swiperList>
					<swiperList v-else jsClass='js_links' :list="indexData.team_introduction_zc" itemType="links"
						:pagination="false" :oneItem="3" :slides="4" ></swiperList>
					<div class="botBox">
						<router-link class="more color" to="/team">クリックしてもっと見る
							<img class="icon hidden-xs-only" src="@/assets/icon_04.png" alt="">
						</router-link>
					</div>
				</div>

			</div>
		</div> -->

    <!-- <div class="partners">
		<titleOne id="about_4" en='partners' title="合作伙伴"></titleOne>
		<div class="widthBox">
			<div class="partnersBox">
				<swiperList v-if="mobile" :list="indexData.cooperative_partner" itemType="partners" jsClass='js_listB' oneItem :pagination="false" :slides="1"></swiperList>
				<swiperList v-else :list="indexData.cooperative_partner" itemType="partners" jsClass='js_listB' :pagination="false" :slides="4"></swiperList>
				<div class="botBox">
					<router-link class="more" to="/partners">クリックしてもっと見る
						<img class="icon" src="@/assets/icon_03.png" alt="">
					</router-link>
				</div>
			</div>
		</div>
	</div> -->


    <div class="contact" v-if="indexData.contact_us" v-cloak>
      <titleOne id="about_6" en='Contact us' title="お問い合わせ"></titleOne>
      <div class="widthBox contactBox hidden-xs-only">
        <h4>連絡先</h4>
        <h4 class="mini_h4">日本麗源商事株式会社</h4>
        <div class="item">
          <span class="iconfont icon-dianhuatianchong"></span>
          <div class="cont">
            <div class="name">電話番号:</div>
            <div class="tel">{{ indexData.contact_us.phone }}</div>
          </div>
        </div>
        <div class="item">
          <span class="icon el-icon-tickets"></span>
          <div class="cont">
            <div class="name">郵便番号：</div>
            <div class="tel">{{ indexData.contact_us.zipcode }}</div>
          </div>
        </div>
        <div class="item">
          <span class="iconfont icon-wangluo"></span>
          <div class="cont">
            <div class="name">ＨＰ:</div>
            <div class="tel">{{ indexData.contact_us.web_url }}</div>
          </div>
        </div>

        <div class="item">
          <span class="iconfont"><img src="@/assets/emil.png" class="emil" alt=""></span>
          <div class="cont">
            <div class="name">E-MAIL:</div>
            <div class="tel">{{ indexData.contact_us.email }}</div>
          </div>
        </div>
        <h4 class="mat100">交通アクセス</h4>
        <div class="item">
          <span class="iconfont icon-ditu-dibiao"></span>

          <div class="cont">
            <div class="name">会社所在地:</div>
            <div class="tel">{{ indexData.contact_us.address }}</div>
          </div>
        </div>
      </div>
      <div class="m_contact_box hidden-sm-and-up">
        <h4>連絡先</h4>
        <h4 class="mini_h4">日本麗源商事株式会社</h4>
        <div class="item">
          <span class="iconfont icon-dianhuatianchong"></span>
          <div class="cont">
            <div class="name">電話番号:</div>
            <div class="tel">{{ indexData.contact_us.phone }}</div>
          </div>
        </div>

        <div class="item">
          <span class="icon el-icon-tickets"></span>
          <div class="cont">
            <div class="name">郵便番号：</div>
            <div class="tel">{{ indexData.contact_us.zipcode }}</div>
          </div>
        </div>
        <div class="item">
          <span class="iconfont icon-wangluo"></span>
          <div class="cont">
            <div class="name">ＨＰ:</div>
            <div class="tel">{{ indexData.contact_us.web_url }}</div>
          </div>
        </div>

        <div class="item">
          <span class="iconfont"><img src="@/assets/emil.png" class="emil" alt=""></span>
          <div class="cont">
            <div class="name">E-MAIL:</div>
            <div class="tel">{{ indexData.contact_us.email }}</div>
          </div>
        </div>
        <h4 class="mat100">交通アクセス</h4>
        <div class="item">
          <span class="iconfont icon-ditu-dibiao"></span>

          <div class="cont">
            <div class="name">会社所在地:</div>
            <div class="tel">{{ indexData.contact_us.address }}</div>
          </div>
        </div>
      </div>
      <!-- <baidu-map class="map" ak="iItrfVQKT5urUdfSGmSEtw0aCVAj98iw" :zoom="15" :center="center">
				<bm-marker :position="{ lng: center.lng, lat: center.lat }" :dragging="true"
					animation="BMAP_ANIMATION_BOUNCE"></bm-marker>
			</baidu-map> -->
      <!-- {{ center.lng }} {{ center.lat }} -->
      <googleMap :lat="center.lat" :lng="center.lng"></googleMap>
    </div>

    <div class="branch" style="border: none;">
      <titleOne class="hidden-xs-only" id="about_3" en='team introduction' title="その他の拠点"></titleOne>
      <h4 class="hidden-sm-and-up">その他の拠点</h4>
      <div class="branch-body">
        <div class="item " :style="'background: url(' + item.img + ');background-size: cover;background-position: center;'"
          v-for="item in sub_company_list" :key="item">
          <div class="img">
            <img :src="item.nation_img" alt="">
          </div>
          <div class="txt" style="white-space: pre-wrap;">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import titleOne from '@/components/titleOne.vue'
import banner from '@/components/banner.vue'
import bannerA from '@/components/bannerA.vue'
import swiperList from '@/components/swiper_list.vue'
import recruitBox from '@/components/recruit.vue'
import googleMap from '@/components/googleMap.vue'
export default {
  name: 'About',
  components: {
    titleOne,
    banner,
    bannerA,
    swiperList,
    recruitBox,
    googleMap
  },
  computed: {
    bannerS() {
      return this.$store.state.bannerS
    },
  },
  data: function () {
    return {
      indexData: {},
      center: { lng: 0, lat: 0 },
      mobile: false,
      sub_company_list: []
    };
  },
  created() {
    var width = document.body.clientWidth
    if (width <= 768) {
      this.mobile = true
    }
    this.$api.get('home/index/enterpriseIntroduction', {}).then(res => {
      this.indexData = res.data
      this.center.lng = res.data.contact_us.lng
      this.center.lat = res.data.contact_us.lat
      this.sub_company_list = res.data.sub_company
    })
  },
  mounted() {
    this.$nextTick(function () {
      if (this.$route.query.to != undefined || this.$route.query.to != null) {
        setTimeout(() => {
          document.getElementById(this.$route.query.to).scrollIntoView()
        }, 500)
      } else {
        document.body.scrollIntoView()
      }
    })
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped="scoped">
$paddingTop: 60px;

.map {
  width: 100%;
  height: 300px;

  .BMap_cpyCtrl {
    display: none !important;
  }

  .anchorBL {
    display: none !important;
  }
}

.about {
  .profile {
    padding-top: $paddingTop;

    .proFileBox {
      .item {
        margin-top: 40px;

        .pic {
          width: calc(50% - 20px);
        }

        .txt {
          width: 50%;

          .en {
            margin-top: 70px;
            font-size: 40px;
            color: rgba($color: #1D2A55, $alpha: 0.35);
          }

          h4 {
            font-size: 48px;
            color: #1D2A55;
          }

          .cont {
            margin-top: 60px;
            padding: 20px 30px;
            background: rgba($color: #1D2A55, $alpha: 0.05);
            font-size: 24px;
            line-height: 150%;
            height: 450px;
            color: #1D2A55;
            padding-right: 25%;
            overflow-y: auto;
          }
        }

        &:nth-child(2n) {
          .en {
            text-align: right;
          }

          h4 {
            text-align: right;
          }

          .cont {
            padding-right: 30px;
            padding-left: 25%;
          }
        }
      }
    }
  }

  .honor {
    padding-top: $paddingTop;
    border-top: 1px solid #1D2A55;
    margin-top: 50px;

    .honorBox {
      padding: 50px 0 40px;
    }
  }

  .team {
    width: 1400px;
    margin: 0 auto;
    padding-top: $paddingTop;
    border-top: 1px solid #1D2A55;
    margin-top: 50px;

    .teamBox {
      padding: 90px 0 60px;
    }

    .teamBox2 {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;

      .left {
        width: 50%;

        img {
          width: 100%;
        }
      }

      .right {
        flex: 1;
        margin-left: 42px;
        position: relative;

        .name {
          font-size: 48px;
          padding-top: 50px;
          padding-bottom: 20px;
          color: #1d2a55;
        }

        .more {
          cursor: pointer;
          position: absolute;
          left: 0;
          bottom: 0;
          padding: 11px 6px;
          background: #1d2a55;
          font-size: 20px;
          color: #fff;
          margin-bottom: 5px;
        }

        .title {
          padding-bottom: 40px;
        }

        .title,
        .info {
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #1d2a55;
          opacity: 0.8;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .branch {
    padding-top: $paddingTop;
    border-top: 1px solid #1D2A55;
    margin-top: 50px;

    .branch-body {
      width: 1400px;
      margin: 0 auto;
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .item{
        width: 48%;
      }
      .txt{
        word-wrap: anywhere;
      }
    }

    h4 {
      font-size: 48px;
      text-align: center;
      color: #1D2A55;
    }

    .item {
      width: 49%;
      height: 380px;
      margin-top: 20px;
      padding: 138px 90px;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      .img {
        width: 210px;

        img {
          width: 210px;
          height: 110px;
        }
      }

      .txt {
        font-size: 40px;
        color: #fff;
        margin-left: 25px;
      }

      .left {
        width: 40%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .right {
        flex: 1;
        margin-left: 40px;

        p:nth-child(1) {
          margin-bottom: 30px;
          font-size: 46px;
        }

        p {
          font-size: 42px;
          margin: 10px 0;
        }
      }
    }
}

.Links {
  background: rgba($color: #1D2A55, $alpha: 1.0);

  .linksBox {
    overflow: hidden;
    padding: 100px 0 80px;
  }

}

.partners {
  padding-top: $paddingTop;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    background: #FFF;
    left: 0;
    top: 0;
    z-index: -1;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 50%;
    background: rgba($color: #1D2A55, $alpha: 0.05);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }

  .partnersBox {
    padding: 90px 0 60px;
  }


}

.botBox {
  text-align: center;
  margin-top: 50px;

  .more {
    display: inline-block;
    width: 250px;
    height: 50px;
    background: #1D2A55;
    color: #FFF;
    text-align: center;
    line-height: 50px;
    font-size: 18px;
    position: relative;

    &.color {
      border: 1px solid #FFF;
    }

    .icon {
      position: absolute;
      top: 50%;
      right: -54px;
      width: 77px;
      transform: translate(0, -50%);
    }
  }
}

.recruitment {
  padding-top: $paddingTop;
}

.contact {
  padding-top: $paddingTop;
  border-top: 1px solid #1D2A55;
  margin-top: 50px;

  .contactBox {
    h4 {
      font-size: 36px;
      color: #1D2A55;
      margin-top: 50px;

      &.mat100 {
        margin-top: 100px;
      }
    }

    .item {
      margin-top: 40px;

      .icon,
      .iconfont {
        font-size: 36px;
        font-weight: bold;
        color: #1D2A55;
        float: left;
      }

      .emil {
        width: 40px;
        height: 40px;
      }

      img {
        width: 36px;
        height: 36px;
        float: left;
      }

      .cont {
        margin-left: 86px;
        font-size: 24px;
        line-height: 40px;

        &>div {
          font-size: 24px;
          line-height: 40px;
        }
      }
    }
  }

  .map {
    width: 100%;
    margin-top: 30px;
  }
}
}

@media screen and(max-width:1400px) {
  .about {
    .team {
      width: 1200px;

      .teamBox2 {}
    }

    .profile {
      .proFileBox {
        .item {
          .txt {
            .en {
              font-size: 40px;
            }

            h4 {
              font-size: 36px;
            }

            .cont {
              font-size: 18px;
              height: 350px;
            }
          }
        }
      }
    }

    .branch{
			h4{
				font-size: 36px;
        text-align: center;
			}
      .branch-body{
        width: 1200px;
      }
			.item{
				// min-height: 280px;
				.left{
          min-width: 130px;
					width: 40%;
					height: 100%;
					img{
          min-width: 130px;
						width: 100%;
						height: 100%;
            object-fit: contain;
					}
				}
				.right{
					p:nth-child(1){
						margin-bottom: 30px;
						font-size: 32px;
					}
					p{
						font-size: 28px;
						margin: 10px 0;
					}
				}
			}
		}

    .recruitment {}

    .contact {
      .contactBox {
        h4 {
          font-size: 32px;
        }

        .item {
          img {
            width: 32px;
            height: 32px;
          }

          .cont {
            font-size: 20px;

            .name {
              font-size: 20px;
            }

            .tel {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and(max-width:1200px) {
  .about {

    .team {
      width: 100%;

      .teamBox2 {}
    }

    .profile {
      .proFileBox {
        .item {
          .txt {
            .en {
              font-size: 36px;
              margin-top: 50px;
            }

            h4 {
              font-size: 32px;
            }

            .cont {
              font-size: 16px;
              height: 300px;
              margin-top: 40px;
            }
          }
        }
      }
    }

    .branch{
      .branch-body{
        width: 1000px;
        .item{
          padding: 100px 50px;
        }
      }
    }
    .recruitment {}

    .contact {
      .contactBox {
        h4 {
          font-size: 28px;
        }

        .item {
          img {
            width: 28px;
            height: 28px;
          }

          .cont {
            font-size: 18px;

            .name {
              font-size: 18px;
            }

            .tel {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width:1000px) {
    .branch{
      .branch-body{
        width: 100%;
        display: block;
        .item{
          padding: 100px 50px;
          width: 100%;
        }
      }
    }
  }
@media screen and(max-width:768px) {
  .about {
    padding: 0 16px;
    .branch{
      padding-top: 32px;
      margin-top: 0;
      h4{
        font-size: 18px;
      }
    }
    .team {
      .teamBox2 {
        flex-wrap: wrap;

        .left {
          width: 100%;
        }

        .right {
          width: 100%;
          margin-left: 0;

          .name {
            text-align: center;
            font-size: 14px;
            font-weight: 700;
            padding-top: 20px;
            padding-bottom: 10px;
          }

          .more {
            position: relative;
            width: 124px;
            font-size: 12px;
            text-align: center;
            margin: 0 auto;
            display: block;
          }

          .title {
            text-align: center;
            padding-bottom: 16px;
          }

          .title,
          .info {
            font-size: 13px;
            text-align: center;
          }
          .info{
            margin-bottom: 24px;
          }
        }
      }
    }

    .profile {
      padding-top: 15px;

      .proFileBox {
        .item {
          margin-top: 20px;

          .pic {
            width: 100%;
            height: auto;
          }

          .txt {
            width: 100%;

            .en {
              font-size: 10px;
              line-height: 20px;
              margin-top: 10px;
              text-align: center;
              color: #272E4D;
            }

            h4 {
              font-size: 16px;
              text-align: center;
              line-height: 20px;
              color: #272E4D;
            }

            .cont {
              background: none;
              margin-top: 10px;
              height: auto;
              overflow-y: auto;
              padding: 10px 15px;
              font-size: 14px;
              color: #3E404D;
            }
          }
        }
      }
    }

    .branch{
			.branch-body{
				width: 100%;
        margin-top: 0px;
        .item{
          padding: 60px 40px;
          height: auto;
          width: 100%;
          box-sizing: border-box;
          .img{
              width: 38%;
              min-width: 130px;
              // height: 73px;
            img{
              width: 100%;
              // height: 73px;
              min-width: 130px;
            object-fit: contain;
            }
          }
          .txt{
            margin-left: 10px;
            font-size: 20px;
          }
        }
			}
    }

    .honor {
      margin-top: 20px;
      padding-top: 20px;

      .honorBox {
        padding: 20px;
      }
    }

    .team {
      margin-top: 20px;
      padding-top: 20px;

      .teamBox {
        padding: 20px 20px;
      }
    }

    .Links {
      .linksBox {
        padding: 20px 0 20px;
      }
    }

    .botBox {
      margin-top: 20px;

      .more {
        background: #FFFFFF;
        width: 148px;
        height: 32px;
        line-height: 32px;
        color: #17204D;
        font-size: 12px;

      }
    }

    .partners {
      padding-top: 20px;

      .partnersBox {
        padding: 30px 0 20px;
      }
    }

    .contact {
      margin-top: 20px;
      padding-top: 32px;
      .map {
        height: 200px;
      }

      .m_contact_box {
        margin-top: 20px;
        background: #ffffff;
        border-radius: 6px;
        box-sizing: border-box;
        font-weight: 400;

        .mini_h4 {
          margin-top: 20px;
          font-size: 16px;
          font-weight: 400;
        }

        h4 {
          font-size: 16px;
          line-height: 20px;
          color: #272E4D;
          font-weight: 400;
        }

        .item {
          margin-top: 16px;

          .iconfont,
          .icon {
            float: left;
            font-size: 20px;
            line-height: 20px;
          }

          .emil {
            width: 20px;
            height: 20px;
          }

          .cont {
            margin-left: 32px;
            font-size: 14px;
            line-height: 20px;
            color: #272E4D;
            div{
            font-size: 14px;
            }
          }
        }

        .mat100 {
          margin-top: 20px;
        }
      }
    }
  }
}</style>